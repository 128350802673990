<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showCart=false />
    <SidebarNav />
    <div v-if="submitStatus == 'PENDING'">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="validToken">
      <div v-if="submitStatus == 'OK'">
        <div class="container">
          <div class="row mt-5">
            <div class="col-12 col-md-6 col-lg-4">
              <h1>Your password has been now reset.</h1>
              <h4 class="welcome">We'll log you in automagically.</h4>
            </div> <!--col-4 -->
            <div class="col-0 col-md-6 col-lg-8">
              <img class="img-width d-none d-md-block login-img" src="@/assets/images/reset-password-framme-store.png" />
            </div>
          </div> <!--row -->
        </div>
      </div>
      <div v-if="submitStatus == 'ERROR' || submitStatus == null" class="container">
        <div class="row mt-5">
          <div class="col-12 col-md-6 col-lg-4">
            <h1>Set password</h1>
            <h4 class="welcome">Almost there. Create a new password and you're good to go.</h4>
            <p>It needs to be at least 8 characters long and it can’t just be full of numbers.</p>
            <label for="password">New password</label>
            <input class="inputfield form-control" type="password" autofocus ref="passwordInput" :class="{ 'form-input-error': $v.password.$error}" v-model="password" v-on:blur="$v.password.$touch()">
            <div v-if="$v.password.$error">
              <div class="form-field-error" v-if="!$v.password.required">This field is required</div>
              <div class="form-field-error" v-if="!$v.password.minLength">Password must contain at least 8 characters</div>
              <div class="form-field-error" v-if="!$v.password.notNum && $v.password.minLength">Password can't be entirely numeric.</div>
            </div>
            <button class="btn btn-lg btn-primary btn-block" @click="send()">Set new password</button>
          </div> <!--col-4 -->
          <div class="col-0 col-md-6 col-lg-8">
            <img class="img-width d-none d-md-block login-img" src="@/assets/images/reset-password-framme-store.png" />
          </div>
        </div> <!--row -->
      </div>
    </div>
    <div v-else> <!-- invalid token -->
      <div class="container">
        <div class="row mt-5">
          <div class="col-12 col-md-6 col-lg-4">
            <h1>Oh, an expired link</h1>
            <h4 class="welcome">This set password page has either been used or it has expired. We can send you a new link.</h4>
            <router-link :to="{ name: 'forgot-password'}">
              <button class="btn btn-lg btn-primary btn-block">Set new password</button>
            </router-link>
          </div> <!--col-4 -->
          <div class="col-0 col-md-6 col-lg-8">
            <img class="img-width d-none d-md-block login-img" src="@/assets/images/reset-password-framme-store.png" />
          </div>
        </div> <!--row -->
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import router from '@/router'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import { required, minLength, numeric, not } from 'vuelidate/lib/validators'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

//import Vue from 'vue'

export default {
  name: 'setPassword',
  components: {
    SidebarNav,
    TopNav,
    Footer
  },
  data() {
    return {
      password: null,
      submitStatus: null,
      token: this.$route.params.token,
      uid: this.$route.params.uid,
      validToken: false,
      tokenChecked: false
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      notNum: not(numeric)
    },
  },
  mounted: function () {
    this.checkToken();
    this.$refs.passwordInput.focus();
  },
  methods: {
    checkToken() {
      this.submitStatus = 'PENDING';
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + "/api/stores/token_valid/";
      const data = {
        'token': this.token,
        'uid': this.uid
      }

      document.body.style.cursor='wait';
      axios({
        method: 'post',
        url: api_url,
        data: data
      })
      .then(function () {
        vm.tokenChecked = true;
        vm.validToken = true;
        document.body.style.cursor='default';
        vm.submitStatus = null;
      })
      .catch(function () {
        vm.tokenChecked = true;
        vm.validToken = false;
        vm.submitStatus = null;
        document.body.style.cursor='default';
      })
    },
    send() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const vm = this;
      this.submitStatus = 'PENDING'
      const data = {
        'token': this.token,
        'uid': this.uid,
        'password': this.password
      }
      const api_url =  process.env.VUE_APP_API_URL + "/api/stores/set_password/";
      axios({
        method: "POST",
        url: api_url,
        data: data
      })
      .then(response => {
        vm.submitStatus = 'OK';
        document.body.style.cursor='default';
        const token = response.data.token;
        const stores = response.data.stores;
        vm.$store.dispatch('loginSetup', {token: token, stores: stores, path: response.data.path});
        setTimeout(function() {
          router.push('/' + response.data.path);
        },2500);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.submitStatus = 'ERROR';
        document.body.style.cursor='default';
      })
    }
  }
}
</script>