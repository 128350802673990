<template>
  <div v-if="!hasStores">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav/>
    <!--<div v-if="fetchingProducts || toggling">-->
    <div v-if="toggling">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9 mt-md-5">
          <h1 class="mt-4">Order from inventory</h1>
        </div>
        <div class="d-none col-md-3 d-md-flex flex-row-reverse mt-auto mb-2">
          <span @click="updateView(false)" class="pointer">
            <img v-if="gridView" src="@/assets/images/list-view-inactive.svg" title="List view">
            <img v-else src="@/assets/images/list-view-active.svg" title="List view">
          </span>
          <span @click="updateView(true)" class="pointer mr-2">
            <img v-if="gridView" src="@/assets/images/grid-view-active.svg" title="Grid view">
            <img v-else src="@/assets/images/grid-view-inactive.svg" title="Grid view">
          </span>
        </div>
      </div>
      <div class="row mt-3 mt-md-3">
        <div class="col-3">
          <div class="d-md-none" @click="toggleFilters()">Filters <img src="@/assets/images/arrow.svg" title="arrow" :class="{'arrow-up': showFilters, 'arrow-down': !showFilters}"></div>
          <div class="d-none d-md-block">
            <v-select
              label="name"
              :options="filterProductOptions"
              :reduce="p => p.id"
              :value="$store.getters.nameFilter"
              placeholder="All items"
              :disabled="!initialResultsFetched"
              @input="updateFilters('name', $event)"
            ></v-select>
          </div>
        </div>
        <div class="col-3">
          <div class="d-none d-md-block">
            <v-select
              label="name"
              :options="typeOptions"
              :reduce="c => c.value"
              :value="$store.getters.typeFilter"
              :searchable="false"
              placeholder="All product types"
              :disabled="!initialResultsFetched"
              @input="updateFilters('type', $event)"
            ></v-select>
          </div>
        </div>
        <div class="col-3">
          <div class="d-none d-md-block">
            <v-select
              label="name"
              :options="filterCategories"
              :reduce="c => c.value"
              :value="$store.getters.categoryFilter"
              :searchable="false"
              placeholder="All categories"
              :disabled="!initialResultsFetched"
              @input="updateFilters('category', $event)"
            ></v-select>
          </div>
        </div>
        <div class="col-3 d-none d-md-block">
          <div>
            <v-select
              label="name"
              :options="filterTagOptions"
              :reduce="t => t.value"
              :value="$store.getters.tagFilter"
              multiple
              placeholder="All product tags"
              :disabled="!initialResultsFetched"
              @input="updateFilters('tags', $event)"
            ></v-select>
          </div>
        </div>
        <div class="col-3 d-flex flex-row-reverse d-block d-md-none">
          <span @click="updateView(false)" class="pointer">
            <img v-if="gridView" src="@/assets/images/list-view-inactive.svg" title="List view">
            <img v-else src="@/assets/images/list-view-active.svg" title="List view">
          </span>
          <span @click="updateView(true)" class="pointer mr-2">
            <img v-if="gridView" src="@/assets/images/grid-view-active.svg" title="Grid view">
            <img v-else src="@/assets/images/grid-view-inactive.svg" title="Grid view">
          </span>
        </div>
      </div>
      <div class="row d-md-none filter-slide mobile-filters mt-3" :class="{'height0': !showFilters}">
        <div class="col-12 mb-2">
          <v-select
            label="name"
            :options="filterProductOptions"
            :reduce="p => p.id"
            :value="$store.getters.nameFilter"
            placeholder="All items"
            @input="updateFilters('name', $event)"
          ></v-select>
        </div>
        <div class="col-12 mb-2">
          <v-select
            label="name"
            :options="typeOptions"
            :reduce="c => c.value"
            :value="$store.getters.typeFilter"
            :searchable="false"
            placeholder="All product types"
            @input="updateFilters('type', $event)"
          ></v-select>
        </div>
        <div class="col-12 mb-3">
          <v-select
            label="name"
            :options="filterCategories"
            :reduce="c => c.value"
            :value="$store.getters.categoryFilter"
            :searchable="false"
            placeholder="All categories"
            @input="updateFilters('category', $event)"
          ></v-select>
        </div>
        <div class="col-12 mb-3">
          <v-select
            label="name"
            :options="filterTagOptions"
            :reduce="t => t.value"
            :value="$store.getters.tagFilter"
            multiple
            placeholder="All product tags"
            @input="updateFilters('tags', $event)"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr class="my-0 mt-md-3" style="border-top: 1px solid #dcdcdc;" />
        </div>
      </div>
      <div v-if="!initialResultsFetched">
        <div class="loader-container">
          <div class="row justify-content-center">
            <div class="col-md-4 d-flex justify-content-center">
              <div class="loader">Loading...</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row" v-if="gridView">
          <ProductGridItem v-bind:product=product v-bind:leaseProduct="product.hasOwnProperty('lease_status') ? true : false" v-for="product in products" v-bind:key="product.hasOwnProperty('lease_status') ? 'lease_' + product.id : product.id" />
        </div>
        <div class="row mt-5" v-else>
          <ProductListItem v-bind:product=product v-for="product in products" v-bind:leaseProduct="product.hasOwnProperty('lease_status') ? true : false" v-bind:key="product.hasOwnProperty('lease_status') ? 'lease_' + product.id : product.id" />
        </div>
        <div class="row" v-if="$store.state.moreProductsAvailable || moreLeasesToFetch">
          <div class="col-5">
          </div>
          <div class="col-2">
            <div class="row justify-content-center">
              <div class="mini-loader">Loading...</div>
            </div>
            <div class="mt-2">
              Loading more results...
            </div>
          </div>
          <div class="col-5">
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import ProductGridItem from '../components/ProductGridItem.vue'
import ProductListItem from '../components/ProductListItem.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

export default {
  name: 'ProductList',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    ProductGridItem,
    ProductListItem
  },
    data() {
    return {
      toggling: false,
      showFilters: false,
    }
  },
  created: function () {
    //this.setFiltersWithHash(window.location.hash.substring(1));
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    activeStore () {
      return this.$store.getters.activeStore;
    },
    hasStores () {
      const hasStores = this.$store.getters.hasStores
      if (!hasStores) {
        this.$store.dispatch('fetchStores')
      }
      return hasStores;
    },
    products () {
      let products = this.$store.getters.productsList;
      if(this.$store.getters.activeStore.features && this.$store.getters.activeStore.features.leased_products){
        products = products.concat(this.$store.getters.leaseproducts.filter(lp => (lp.lease_status == 1) && (lp.status == 2)))
      }
      return products;
    },
    fetchingProducts () {
      if(!this.$store.getters.productsFetched){
        this.$store.dispatch('fetchProducts');
        return true;
      }
      return false;
    },
    gridView(){
      return this.$store.getters.gridView;
    },
    options(){
      if(!this.$store.getters['admin/productOptsFetched']){
        this.$store.dispatch('admin/fetchProductOptions')
      }
      return this.$store.getters['admin/productOptions'];
    },
    filterCategories(){
      if(!this.options.categories){
        return [];
      }
      return this.options.categories.map(c => {
        let obj = {}
        obj['name'] = c[1]
        obj['value'] =  c[0]
        return obj
      })
    },
    filterProductOptions(){
      return this.$store.getters.nameFilterOptions;
    },
    typeOptions(){
      let opts = [
        {name: "Stocked products", value: 1},
        {name: "Produced after order", value: 2}
      ]
      if(this.$store.getters.storeFeatures.leased_products){
        opts.push({name: "Leased products", value: 3})
      }
      return opts;
    },
    filterTagOptions(){
      if(this.$store.state.productTagsFetched){
        return this.$store.state.productTags.map(t => {return {name: t.name, value: t.id}})
      }
      return [];
    },
    moreLeasesToFetch(){
      if(this.$store.getters.storeFeatures.leased_products){
        if(!this.$store.state.leaseProductsFetched || this.$store.state.moreLeaseProductsAvailable){
          return true;
        }
      }
      return false;
    },
    initialResultsFetched(){
      if(!this.$store.state.filterOptionsFetched && !this.$store.state.fetchingFilterOptions){
        this.$store.dispatch('fetchFilterOptions');
      }
      if(this.$store.getters.productsFetched){
        // first batch of products fetched
        if(this.$store.getters.products.length > 23){
          return true;
        } else if(this.$store.getters.leaseProductsFetched){
          return true;
        } else if(this.$store.getters.storeFeatures && !this.$store.getters.storeFeatures.leased_products){
          // features fetched and leases disabled
          // dispatch fetching anyway to mark leases fetched
          this.$store.dispatch('fetchLeaseProducts');
          return true;
        } else {
          // There was not enough products available and leases are not yet fetched
          this.$store.dispatch('fetchLeaseProducts');
        }
      } else {
        if(this.hasStores){
          this.$store.dispatch('fetchProducts');
          return false;
        }
      }
      return false;
    }
  },
  methods: {
    updateView(grid){
      if(grid == this.gridView){
        return;
      }
      this.toggling = true;
      this.$store.dispatch('updateGridListView', grid);
      const vm = this;
      setTimeout(() => {
        vm.toggling = false;
      }, 400);
    },
    updateFilters(name, event){
      let value = null;
      if(event !== null){
        value = event.target ? event.target.value : event
      }
      this.$store.dispatch('updateProductFilter', {
        name: name, value: value == "" ? null : value
      });
      // Clear products/leaseproducts from VUEX and fetch first batch from back-end
      //this.$store.dispatch('clearAndFetchProducts', {append: false, filters: newVal});
      this.$store.dispatch('clearLeaseProducts');
      this.$store.dispatch('clearAndFetchProducts', {append: false});
    },
    toggleFilters(){
      this.showFilters = !this.showFilters;
    },
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        if(!this.$store.state.productsFetched || this.$store.state.moreProductsAvailable){
          this.$store.dispatch('fetchProducts', {append: true});
        } else if(!this.$store.state.leaseProductsFetched){
          this.$store.dispatch('fetchLeaseProducts');
        }
        else if(this.$store.state.moreLeaseProductsAvailable){
          this.$store.dispatch('fetchLeaseProducts', {append: true});
        }
      }
    }
  }
}
</script>
