<template>
  <div>
    <TopNav v-bind:showLogin=true v-bind:showCart=false />
    <SidebarNav />
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 col-md-6 col-lg-5">
          <h1>No access to store!</h1>
          <h4 class="mt-4">We just checked that you don’t have access to any active store(s). Please reach out to us if you have questions via email <a href="mailto:info@framme.com">info@framme.com</a> or to the store manager who initially invited you to the store.</h4>
        </div> <!--col-4 -->
        <div class="col-0 col-md-6 col-lg-7">
          <img class="img-width d-none d-md-block login-img" src="@/assets/images/no-active-stores.png" />
        </div>
      </div> <!--row -->
    </div>
    <Footer/>
  </div>
</template>

<script>
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

export default {
  name: 'noActiveStore',
  components: {
    SidebarNav,
    TopNav,
    Footer
  },
}

</script>