<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showCart=false />
    <SidebarNav />
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 col-md-6 col-lg-4">
          <h1>Here you go!</h1>
          <h4 class="welcome">Reset link has been sent to you if you have an account with us. If this email doesn't show up soon, check your spam folder.</h4>
          <router-link to="/">
            <button class="btn btn-lg btn-primary btn-block">Return to login</button>
          </router-link>
        </div> <!--col-4 -->
        <div class="col-0 col-md-6 col-lg-8">
          <img class="img-width d-none d-md-block login-img" src="@/assets/images/forgot-password-email-sent-framme-store.png" />
        </div>
      </div> <!--row -->
    </div>
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
//import router from '@/router'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

export default {
  name: 'forgotPasswordSent',
  components: {
    SidebarNav,
    TopNav,
    Footer
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>