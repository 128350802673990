<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav />
    <div v-if="fetchingProduct">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container">
      <div class="row">
        <div class="col-md-6 mt-3 mt-md-5">
            <ProductPictureGallery v-bind:primaryImage=primaryImage v-bind:images=product.images />
        </div>
        <div class="col-md-6 mt-md-5 pl-md-5 product-info">
          <h1 class="mt-4">{{product.name}}</h1>
          <h3 class="product-price" v-if="!isLeaseProduct">{{product.price}}{{$store.getters.currencyStr}} per item.</h3>
          <div class="description mb-3" v-html="withBR(product.description)"></div>
          <p class="product-details" v-if="product.delivery_display">Estimated delivery {{product.delivery_display.toLowerCase()}}</p>
          <p class="product-details moq" v-if="!isLeaseProduct">Minimum order quantity is {{product.moq}} items.</p>
          <p v-if="product.order_unit" class="product-details">Delivered in packages of {{ product.order_unit}}.</p>
          <div v-if="product.variants && product.variants.length > 0">
            <div class="row">
              <div v-for="(variant, index) in product.variants"  v-bind:key="variant.id" class="col-4 col-lg-3">
                <label v-if="!singleVariant">{{ variant.name }}</label>
                <div class="font-12 product-page-stock" :class="{'mt-4': singleVariant}" v-if="product.stock_management">{{ variant.stock_quantity }} in stock</div>
                <input class="inputfield" type="number" required="" autofocus="" v-model.number="variantCount[index]">
              </div>
            </div>
          </div>
          <div v-else class="mt-4">
            <div>Your order quantity</div>
            <div v-if="isLeaseProduct">1</div>
            <input v-else class="inputfield" type="number" required="" autofocus="" v-model.number="itemCount">
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <button v-if="leaseAlreadyInCart" class="btn btn-lg btn-primary btn-block mt-4" disabled>Already in cart</button>
              <button v-else class="btn btn-lg btn-primary btn-block mt-4" type="submit" @click="addToCart()">Add to cart</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <router-link :to="{ name: 'productlist'}">
                <button class="btn btn-lg btn-outline-primary btn-block mt-1">Back to all products</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import ProductPictureGallery from '@/modules/general/components/ProductPictureGallery.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

export default {
  name: 'Product',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    ProductPictureGallery
  },
  data() {
    return {
      itemCount: 0,
      variantCount: [],
      isLeaseProduct: null,
      product: {origin_country: {code:null,name:null}}
    }
  },
  mounted: function () {
    this.isLeaseProduct = this.$route.name == 'productpage' ? false : true;
    if(this.productData && this.productData.id){
      // make a copy of productData
      if(!this.productData.origin_country || this.productData.origin_country == ""){
        this.product = {...this.productData, ...{origin_country: {code:null,name:null}}};
      } else {
        this.product = {...this.productData}
      }
      // copy gallery images
      if(!this.isLeaseProduct){
        this.product_images = [...this.productData.images];
      }
    } else {
      // No product data available, fetch it
      this.getProductWithSlug();
    }
    if(this.product.variants && this.product.variants.length > 0){
      this.variantCount = new Array(this.product.variants.length).fill(0);
    }
  },
  watch: {
    activeStore: function (newVal, oldVal) {
      if(newVal && !oldVal){
        if(!this.productData){
          // Got activeStore and no productData available
          this.getProductWithSlug();
        }
      }
    }
  },
  computed: {
    productData(){
      let prod = null;
      if(this.isLeaseProduct){
        prod = this.$store.getters.leaseproducts.find(p => p.slug == this.$route.params.slug);
      } else {
        prod = this.$store.getters.products.find(p => p.slug == this.$route.params.slug);
      }
      return prod;
    },
    fetchingProduct () {
      if(this.isLeaseProduct === null){
        return true;
      }
      // still fetching if no id
      return this.product.id ? false : true;
    },
    variantCounts(){
      if(this.isLeaseProduct){
        return 0;
      }
      const cartProducts = this.$store.getters.cartItems;
      let totCount = 0;
      let cartCount = 0;
      // combine all variant counts
      for(const [index, variant] of this.product.variants.entries()){
        const inCart = cartProducts.find(p => (p.productId == this.product.id) && (p.variantId == variant.id))
        totCount += this.variantCount[index]
        if(inCart){
          cartCount += inCart.count;
          totCount += inCart.count;
        }
      }
      return {totCount, cartCount};
    },
    leaseAlreadyInCart(){
      if(this.isLeaseProduct){
        const found = this.$store.getters.cartLeaseItems.findIndex(li => li.leaseProductId == this.product.id);
        return found >= 0 ? true : false;
      } else {
        return false;
      }
    },
    singleVariant(){
      return this.product.variants.length == 1;
    },
    activeStore(){
      return this.$store.state.activeStore ? true : false;
    },
    primaryImage(){
      return this.product.primary_thumb_big ? this.product.primary_thumb_big : this.product.primary_image;
    }
  },
  methods: {
    addToCart(){
      if(!this.itemCount && this.product.variants && this.product.variants.length === 0){
        return;
      }
      // check order units
      if(this.product.order_unit && !this.checkUnit()){
        return;
      }
      // check moq
      if(this.product.moq && !this.checkMoq()){
        return;
      }
      if(this.product.variants && this.product.variants.length > 0){
        // Check stock levels
        if(this.product.stock_management && !this.checkStock()){
          return;
        }
        // Loop all and add corresponding variantCount to cart
        for(const [index, variant] of this.product.variants.entries()){
          // Check if this variant should be added
          if(this.variantCount[index] > 0){
            // If enough stock or no stock management, add to cart
            const item = {productId: this.product.id, count: this.variantCount[index], variantId: variant.id}
            this.$store.commit('addProductToCart', item);
            this.variantCount.splice(index, 1, 0);
          }
        }
      } else {
        // No variants, add itemCount to cart
        if(this.isLeaseProduct){
          const item = {leaseProductId: this.product.id, count: 1}
          this.$store.commit('addLeaseProductToCart', item);
        } else {
          const item = {productId: this.product.id, count: this.itemCount}
          this.$store.commit('addProductToCart', item);
          this.itemCount = 0;
        }
      }
    },
    checkStock(){
      let nok = false;
      const cartProducts = this.$store.getters.cartItems;
      for(const [index, variant] of this.product.variants.entries()){
        // Check if variant already in cart
        const inCart = cartProducts.find(p => (p.productId == this.product.id) && (p.variantId == variant.id))
        let count = this.variantCount[index]
        if(inCart){
          count += inCart.count;
        }
        if(variant.stock_quantity < count){
          let errMsg = "Not enough products in stock for variant " + variant.name
          if(inCart){
            errMsg += ". You already have " + inCart.count + " item(s) in cart.";
          }
          this.$toastr.e(
            errMsg
          );
          nok = true;
        }
      }
      if(nok){
        return false;
      }
      return true;
    },
    checkUnit(){
      if(this.isLeaseProduct){ return true}

      if(this.product.variants && this.product.variants.length > 0){
        const {totCount, cartCount} = this.variantCounts;
        if(totCount % this.product.order_unit != 0){
          let errMsg = this.product.name + " can be only delivered in packaging units of " + this.product.order_unit;
          if(cartCount > 0){
            errMsg += ". There's already " + cartCount + " items in cart (including all variants)"
          }
          this.$toastr.e(
            errMsg
          );
          return false;
        }
        return true;
      } else {
        // no variants
        const cartProducts = this.$store.getters.cartItems;
        const inCart = cartProducts.find(p => (p.productId == this.product.id))
        const totCount = inCart ? inCart.count + this.itemCount: this.itemCount;
        if(totCount % this.product.order_unit != 0){
          let errMsg = this.product.name + " can be only delivered in packaging units of " + this.product.order_unit;
          if(inCart && inCart.count > 0){
            errMsg += ". There's already " + inCart.count + " items in cart."
          }
          this.$toastr.e(
            errMsg
          );
          return false;
        }
        return true;
      }
    },
    checkMoq(){
      if(this.isLeaseProduct){ return true}

      if(this.product.variants && this.product.variants.length > 0){
        // has variants
        const {totCount} = this.variantCounts;
        if(this.product.moq && this.product.moq > totCount){
          let errMsg =  "Minimum order quantity for "+ this.product.name + " is " + this.product.moq;
          this.$toastr.e(
            errMsg
          );
          return false;
        }
      } else {
        // no variants
        const cartProducts = this.$store.getters.cartItems;
        const inCart = cartProducts.find(p => (p.productId == this.product.id))
        const totCount = inCart ? inCart.count + this.itemCount: this.itemCount;
        if(this.product.moq && totCount < this.product.moq){
          let errMsg =  "Minimum order quantity for "+ this.product.name + " is " + this.product.moq;
          this.$toastr.e(
            errMsg
          );
          return false;
        }
      }
      return true;
    },
    withBR(replace_str){
      return replace_str ? replace_str.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
    },
    getProductWithSlug(){
      if(this.$store.state.activeStore){
        const vm = this;
        let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/products/slug/' + this.$route.params.slug + "/";
        if(this.isLeaseProduct){
          api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/leaseproducts/slug/' + this.$route.params.slug + "/";
        }
        let method = 'GET';
        this.$http({
          method: method,
          url: api_url,
        })
        .then(function (response) {
          vm.product = {...response.data}
          if(vm.product.variants && vm.product.variants.length > 0){
            vm.variantCount = new Array(vm.product.variants.length).fill(0);
          }
          vm.updating = false;
        })
        .catch(function (error) {
          if (error.request){
            vm.$toastr.e(
              error.request.responseText
            );
          } else {
            vm.$toastr.e(
              error
            );
          }
          vm.updating = false;
        });
      }
    }
  }
}
</script>