<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showCart=false />
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 col-md-6 col-lg-4">
          <h1>Dive back in.</h1>
          <h4 class="welcome">Login to your store to order and manage your brand collaterals.</h4>
          <div v-if="updating">
            <div class="loader-container">
              <div class="row justify-content-center">
                <div class="col-md-4 d-flex justify-content-center">
                  <div class="loader">Loading...</div>
                </div>
              </div>
            </div>
          </div>
          <form class="form-login needs-validation" v-on:submit.prevent="login()">
            <label for="email">Email address</label>
            <input id="email" class="inputfield form-control" type="text" autofocus ref="usernameInput" :class="{ 'form-input-error': $v.username.$error}" v-model.trim="username" v-on:blur="$v.username.$touch()">
            <div v-if="$v.username.$error">
              <div class="form-field-error" v-if="!$v.username.required">This field is required</div>
              <div class="form-field-error" v-if="!$v.username.email">Please check your email address.</div>
            </div>
            <div class="form-field-error" v-if="noMatch">
              Oops! Looks like the username and password don't match.
            </div>
            <label for="password">Password</label>
            <input id="password" class="inputfield form-control" type="password" :class="{ 'form-input-error': $v.password.$error}" v-model.trim="password" v-on:blur="$v.password.$touch()">
            <div v-if="$v.password.$error">
              <div class="form-field-error" v-if="!$v.password.required">This field is required</div>
            </div>
            <div class="form-field-error" v-if="noMatch">
              Oops! Looks like the username and password don't match.
            </div>
            <button id="loginbtn" class="btn btn-lg btn-primary btn-block" type="submit">Login</button>
            <div class="d-flex flex-row justify-content keep-me-in">
              <div class="custom-control custom-checkbox flex-fill">
                <input type="checkbox" class="custom-control-input" id="customControlInline">
                <label class="custom-control-label" for="customControlInline">Keep me logged in</label>
              </div>
              <div class="flex-fill text-right">
                <router-link to="/login/forgot-password">Forgot password?</router-link>
              </div>
            </div>
          </form>
        </div> <!--col-4 -->
        <div class="col-0 col-md-6 col-lg-8">
          <img class="img-width d-none d-md-block login-img" src="@/assets/images/dive.png" />
        </div>
      </div> <!--row -->
    </div>
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
import router from '@/router'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'login',
  components: {
    TopNav,
    Footer
  },
  data() {
    return {
      username: null,
      password: null,
      submitStatus: null,
      noMatch: false,
      updating: false
    }
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required
    },
  },
  mounted: function () {
    this.$refs.usernameInput.focus();
  },
  computed: {
    apiToken () {
      return this.$store.getters.apiToken;
    }
  },
  methods: {
    login() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.updating = true;
      const vm = this;
      this.submitStatus = 'PENDING'
      const data = {
        'username': this.username,
        'password': this.password
      }

      this.$store.dispatch('login', data)
      .then(function (response) {
        vm.updating = false;
        vm.submitStatus = 'OK';
        let redirect_path = null;
        if(response.data.stores.length == 0) {
          vm.$store.dispatch('logout')
          redirect_path = 'no-active-store';
        } else if (vm.$route.params.storename){
          if (response.data.stores.find(s => s.path == vm.$route.params.storename)) {
            redirect_path = vm.$route.params.storename;
          } else {
            redirect_path = response.data.stores[0].path;
          }
        } else if(response.data.stores.length > 0) {
          const default_store = response.data.stores.find(s => s.default_store);
          redirect_path = default_store ? default_store.path : response.data.stores[0].path
        } else {
          // No allowed stores
          redirect_path = 'login';
          return;
        }
        document.body.style.cursor='default';
        router.push('/' + redirect_path);
      })
      .catch(function (error) {
        if (error.response && error.response.data){
          vm.$toastr.e(
            error.response.data.error
          );
        }
        vm.submitStatus = 'ERROR'
        vm.updating = false;
        document.body.style.cursor='default';
      })
    }
  }
}
</script>