<template>
  <div class="col-12" >
    <div class="row">
      <div class="col-6 col-sm-1 mb-3 mb-sm-0">
        <img v-if="!product.primary_thumb_small || product.primary_thumb_small.legth == 0" src="@/assets/images/product-photo-coming-soon.jpg" class="product-card-image img-width" alt="">
        <img v-else class="img-width" :src="getImgUrl" />
      </div>
      <div class="col-6 col-sm-5">
        <div class="row">
          <div class="col-sm-5 mb-3 mb-sm-0">
            <router-link :to="{ name: leaseProduct ? 'leaseproductpage' : 'productpage', params: { slug: product.slug }}" style="color: black;">
              {{product.name}}
            </router-link><br />
            <span v-if="leaseProduct" class="price">Lease product</span>
            <span v-else class="price">{{product.price}}{{$store.getters.currencyStr}} per item</span>
          </div>
          <div class="col-sm-7 mb-3 mb-sm-0">
            <div>In stock</div>
            <div class="d-none d-sm-block">Minimum order quantity: {{product.moq}}</div>
            <div class="d-sm-none d-block">MOQ: {{product.moq}}</div>
            <div>Packaging unit: {{product.order_unit ? product.order_unit : "N/A"}}</div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div v-if="product.variants && product.variants.length > 0">
          <div class="row">
            <div v-for="(variant, index) in product.variants"  v-bind:key="variant.id" class="col-sm-6" :class="[singleVariant ? 'col-12': 'col-6']">
              <div v-if="product.variants.length != 1">{{ variant.name }}</div>
              <div v-else>Order quantity</div>
              <div v-if="product.stock_management">
                <div class="font-12 product-page-stock" v-if="variant.stock_quantity > 0">{{ variant.stock_quantity }} in stock</div>
                <div class="font-12 product-page-stock" v-else>Out of stock</div>
              </div>
              <input class="inputfield mb-3 mb-sm-0" type="number" required="" autofocus="" v-model.number="cartVariantCount[index]">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12 col-sm-6">
              Order quantity
              <div v-if="product.hasOwnProperty('lease_status')">1</div>
              <input v-else class="inputfield mb-3 mb-sm-0" type="number" required="" autofocus="" v-model.number="cartItemCount">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <button v-if="leaseAlreadyInCart" class="btn btn-lg btn-primary btn-block mt-4" disabled>Already in cart</button>
        <div v-else>
          <button v-if="product.stock_management && !product.variants.find(v => v.stock_quantity > 0)" class="btn btn-lg btn-primary btn-block mt-4" disabled>Out of stock</button>
          <button v-else class="btn btn-lg btn-outline-primary btn-block mt-4" @click="addToCart()">Add to cart</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductListItem',
  props: {
    product: Object,
    leaseProduct: Boolean,
  },
  data() {
    return {
      cartItemCount: 0,
      cartVariantCount: [],
    }
  },
  mounted(){
    if(this.product.variants && this.product.variants.length > 0){
      this.cartVariantCount = new Array(this.product.variants.length).fill(0);
    }
  },
  computed: {
    getImgUrl(){
      const thumb_url = this.product.primary_thumb_big;
      if(thumb_url == null){
        return "";
      }
      if(thumb_url && thumb_url.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return thumb_url;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + thumb_url;
      }
    },
    variantCounts(){
      const cartProducts = this.$store.getters.cartItems;
      let totCount = 0;
      let cartCount = 0;
      // combine all variant counts
      for(const [index, variant] of this.product.variants.entries()){
        const inCart = cartProducts.find(p => (p.productId == this.product.id) && (p.variantId == variant.id))
        totCount += this.cartVariantCount[index]
        if(inCart){
          cartCount += inCart.count;
          totCount += inCart.count;
        }
      }
      return {totCount, cartCount};
    },
    leaseAlreadyInCart(){
      if(this.product.hasOwnProperty('lease_status')){
        const found = this.$store.getters.cartLeaseItems.findIndex(li => li.leaseProductId == this.product.id);
        return found >= 0 ? true : false;
      } else {
        return false;
      }
    },
    singleVariant(){
      return this.product.variants.length == 1;
    }
  },
  methods: {
    addToCart(){
      if(this.product.moq && !this.checkMoq()){ return }
      if(this.product.order_unit &&  !this.checkUnit()){ return }
      if(this.product.stock_management && !this.checkStock()){ return }
      if(this.product.variants && this.product.variants.length > 0){
        // Loop all and add corresponding variantCount to cart
        for(const [index, variant] of this.product.variants.entries()){
          // Check if this variant should be added
          if(this.cartVariantCount[index] > 0){
            // If enough stock or no stock management, add to cart
            const item = {productId: this.product.id, count: this.cartVariantCount[index], variantId: variant.id}
            this.$store.commit('addProductToCart', item);
            this.cartVariantCount.splice(index, 1, 0);
          }
        }
      } else {
        // No variants, add cartItemCount to cart
        if(this.product.hasOwnProperty('lease_status')){
          const item = {leaseProductId: this.product.id, count: 1}
          this.$store.commit('addLeaseProductToCart', item);
        } else {
          const item = {productId: this.product.id, count: this.cartItemCount}
          this.$store.commit('addProductToCart', item);
        }
        this.cartItemCount = 0;
      }
    },
    checkMoq(){
      if(this.product.variants && this.product.variants.length > 0){
        // has variants
        const {totCount} = this.variantCounts;
        if(this.product.moq && this.product.moq > totCount){
          let errMsg =  "Minimum order quantity for "+ this.product.name + " is " + this.product.moq;
          this.$toastr.e(
            errMsg
          );
          return false;
        }
      } else {
        // no variants
        const cartProducts = this.$store.getters.cartItems;
        const inCart = cartProducts.find(p => (p.productId == this.product.id))
        const totCount = inCart ? inCart.count + this.cartItemCount: this.cartItemCount;
        if(this.product.moq && totCount < this.product.moq){
          let errMsg =  "Minimum order quantity for "+ this.product.name + " is " + this.product.moq;
          this.$toastr.e(
            errMsg
          );
          return false;
        }
      }
      return true;
    },
    checkUnit(){
      if(this.product.variants && this.product.variants.length > 0){
        const {totCount, cartCount} = this.variantCounts;
        if(totCount % this.product.order_unit != 0){
          let errMsg = this.product.name + " can be only delivered in packaging units of " + this.product.order_unit;
          if(cartCount > 0){
            errMsg += ". There's already " + cartCount + " items in cart (including all variants)"
          }
          this.$toastr.e(
            errMsg
          );
          return false;
        }
        return true;
      } else {
        // no variants
        const cartProducts = this.$store.getters.cartItems;
        const inCart = cartProducts.find(p => (p.productId == this.product.id))
        const totCount = inCart ? inCart.count + this.cartItemCount: this.cartItemCount;
        if(totCount % this.product.order_unit != 0){
          let errMsg = this.product.name + " can be only delivered in packaging units of " + this.product.order_unit;
          if(inCart && inCart.count > 0){
            errMsg += ". There's already " + inCart.count + " items in cart."
          }
          this.$toastr.e(
            errMsg
          );
          return false;
        }
        return true;
      }
    },
    checkStock(){
      let nok = false;
      const cartProducts = this.$store.getters.cartItems;
      for(const [index, variant] of this.product.variants.entries()){
        // Check if variant already in cart
        const inCart = cartProducts.find(p => (p.productId == this.product.id) && (p.variantId == variant.id))
        let count = this.cartVariantCount[index]
        if(inCart){
          count += inCart.count;
        }
        if(variant.stock_quantity < count){
          let errMsg = "Not enough products in stock for variant " + variant.name
          if(inCart){
            errMsg += ". You already have " + inCart.count + " item(s) in cart.";
          }
          this.$toastr.e(
            errMsg
          );
          nok = true;
        }
      }
      if(nok){
        return false;
      }
      return true;
    },
  }
}
</script>
