<template>
  <div class="col-6 col-lg-3 mt-4 grid d-flex align-items-end" >
    <router-link :to="{ name: leaseProduct ? 'leaseproductpage' : 'productpage', params: { slug: product.slug }}">
      <div class="product-card">
        <img v-if="!product.primary_thumb_big || product.primary_thumb_big.legth == 0" src="@/assets/images/product-photo-coming-soon.jpg" class="product-card-image" alt="">
        <img v-else :src="getImgUrl" class="product-card-image" alt="">
        <div class="product-description-grid">
          <h5 class="product-title">{{product.name}}</h5>
          <div v-if="leaseProduct">
            <div class="price"><span class="tag-grey">Lease product</span></div>
          </div>
          <div v-else>
            <div>{{product.price}}{{$store.getters.currencyStr}} per item</div>
            <div v-if="product.stock_management">
              <div v-if="!product.variants.find(v => v.stock_quantity > 0)">
                <span class="tag-red">Out of stock</span>
              </div>
              <div v-else-if="product.variants && product.variants.length == 1" class="in-stock">
                <span class="tag-grey">{{ product.variants[0].stock_quantity }} in stock</span>
              </div>
              <div v-else-if="product.variants && product.variants.length > 1" class="in-stock">
                <span v-for="(v, index) of product.variants" v-bind:key="index" :class="{'tag-grey': v.stock_quantity, 'tag-red': !v.stock_quantity}">
                  {{ v.name }} {{ v.stock_quantity }}
                </span>
              </div>
            </div>
            <div v-else>
              <span class="tag-grey">Produced after order</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'ProductGridItem',
  props: {
    product: Object,
    leaseProduct: Boolean,
  },
  computed: {
    getImgUrl(){
      const path = this.product.primary_thumb_big;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
  },
}
</script>