<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showCart=false />
    <div v-if="submitStatus == 'PENDING'">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 col-md-6 col-lg-4">
          <h1>Forgot password?</h1>
          <h4 class="welcome">Don't you worry. We’ll email you instructions on how to reset your password safely.</h4>
          <label for="email">Email address</label>
          <input class="inputfield form-control" type="text" autofocus ref="emailInput" :class="{ 'form-input-error': $v.email.$error}" v-model="email" v-on:blur="$v.email.$touch()">
          <div v-if="$v.email.$error">
            <div class="form-field-error" v-if="!$v.email.required">This field is required</div>
            <div class="form-field-error" v-if="!$v.email.email">Please check your email address.</div>
          </div>
          <button class="btn btn-lg btn-primary btn-block" @click="send()">Reset password</button>
          <div class="flex-fill text-right mt-2">
            <router-link to="/">Return to login</router-link>
          </div>
        </div> <!--col-4 -->
        <div class="col-0 col-md-6 col-lg-8">
          <img class="img-width d-none d-md-block login-img" src="@/assets/images/forgot-password-framme-store.png" />
        </div>
      </div> <!--row -->
    </div>
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import router from '@/router'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'forgotPassword',
  components: {
    TopNav,
    Footer
  },
  data() {
    return {
      email: null,
      submitStatus: null,
    }
  },
  validations: {
    email: {
      required,
      email
    },
  },
  mounted: function () {
    this.$refs.emailInput.focus();
  },
  methods: {
    send() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const vm = this;
      this.submitStatus = 'PENDING'
      const data = {
        'email': this.email
      }
      const api_url =  process.env.VUE_APP_API_URL + "/api/stores/forgot_password/";
      axios({
        method: "POST",
        url: api_url,
        data: data
      })
      .then(function () {
        vm.submitStatus = 'OK';
        document.body.style.cursor='default';
        router.push('/login/forgot-email-sent');
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.submitStatus = 'ERROR';
        document.body.style.cursor='default';
      })
    }
  }
}
</script>